import { MAIN_ROUTE_AI_FW } from "./lib/constants";
import { getTsgState } from "./utils/getTsgState"

const rootComponent = () => async () => await import("./components/core/Root");

const { NODE_ENV } = process.env;
const env = NODE_ENV || "development";
const isDev = env === "development";
console.log(
    `%c@reporting-n-viz/ai-firewall: Jan 15`,
    "display: block; font-size: 21px; border: 2px solid teal; border-radius: 8px; padding: 16px; margin: 16px",
);
export default async ({ vars, sparkyFramework }) => {
    return async function tsgLoad() {
        const { isAvailable, isUserPreferencesSupported, isPDF } = getTsgState();
        return {
            navigation: [
                {
                    key: "insights",
                    path: `/insights`,
                    mergeNav: true,
                    children: [
                        {
                            key: "ai_security",
                            title: "AI Runtime Security",
                            path: MAIN_ROUTE_AI_FW,
                            component: rootComponent(),
                            className: "parent-node",
                            contentClassName: "panwds-tw3" + (isUserPreferencesSupported ? "" : " sparky-dark-only"),
                            mergeNav: true,
                            expandable: false,
                            callAvail: true,
                            hidden: false,
                            avail: isAvailable,
                            noRBACheck: true,
                        },
                    ],
                },
            ],
        };
    };
};
export { default as extensions } from "./extensions";
