import { getAuthState } from "@sparky/framework";
import {
    PDF_REPORTS_EXT_POINT,
    type TPDFReportTypeExtensionPoint,
} from "@ui-platform/ui-core-services-extension-points";

export default {
    point: PDF_REPORTS_EXT_POINT,
    configs: [
        {
            id: "airs.slr",
            config: {
                name: "SLR Report",
                description: "SLR Report....",
                report_type: "airs.slr",
                category: "summary",
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                isAllowed: (state: any) => Boolean(state.airs?.slrAvailable),
                params: () => ({ timeInterval: "7d", email: getAuthState().user.email, region: "americas" }),
                version: 2,
            },
        },
    ],
} satisfies TPDFReportTypeExtensionPoint;