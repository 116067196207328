
import { SPARKY_NAV3_ROUTES_EXTPOINT } from "@sparky/framework/extpoints";
import { MAIN_ROUTE_AI_FW_V3 } from "../lib/constants";
import { getState } from "@sparky/framework";

export default {
    point: SPARKY_NAV3_ROUTES_EXTPOINT,
    configs: [
        {
            id: "netsec.airuntime.routes.security.insights.ai.runtime.security",
            config: {
                path: MAIN_ROUTE_AI_FW_V3,
                stateLoaders: ["auth", "airsNavState"],
                contentClassName:
                    "panwds-tw3" + (getState()?.airsNavState?.isUserPreferencesSupported ? "" : " sparky-dark-only"), // contentClassName should always besides the component, panwds-tw3 is needed for PanWDS
                component: async () => await import("../components/core/Root"), // any component must be dynamically import like this
                isAllowed: () => {
                    const state = getState()?.airsNavState;
                    return state?.isAvailable();
                },
            },
        },
    ],
};