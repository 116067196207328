
import { getFrameworkVars, getAuthState, getFeatureFlag } from "@sparky/framework";
import { hasIOTSecurityRole, hasSLSRole } from "../utils/rbac";

export function isAvailable() {
    // display AI Runtime for AIOPS premium tenants with CDL license
    // TODO revisit condition along with SCM pricing and packaging
    const isPremium = !getFrameworkVars().aiops_free;
    const auth = getAuthState();
    const hasCDL = auth?.instances?.isRunningStatus("logging_service");
    const enableBlockIOTRoles = getFeatureFlag("vnr.rbac_block_iot_roles") ?? false;
    const enableBlockSLSRoles = getFeatureFlag("vnr.rbac_block_sls_roles") ?? false;
    // const enforceRbacV2 = getFeatureFlag?.("vnr.rbac_v2") ?? false;

    if (!isPremium) {
      return false;
    }
    if (!hasCDL) {
      return false;
    }
    if(enableBlockIOTRoles && hasIOTSecurityRole(auth)) {
      return false;
    }
    if(enableBlockSLSRoles && hasSLSRole(auth)) {
        return false;
    }
    return true;
}