
/* eslint-disable @typescript-eslint/no-var-requires */

import { SPARKY_NAV3_TSG_STATE_LOADERS_EXTPOINT } from "@sparky/framework/extpoints";

import { getTsgState } from "../utils/getTsgState";

export default {
    point: SPARKY_NAV3_TSG_STATE_LOADERS_EXTPOINT,
    configs: [
        {
            id: "sparky.nav.state.loaders.airsNavState",
            config: {
                name: "airsNavState", // this is the key, means it can access by state.main
                requireFetch: true,
                loader: async () => {
                    const tsgState = getTsgState();
                    return tsgState;
                },
            },
        },
    ],
};